<template>
  <v-main
    :style="styles.app"
  >
    <container />
  </v-main>
</template>

<script>
import CommonMixin from '@/mixins/CommonMixin'
import ViewMixin from '@/mixins/ViewMixin'
import Container from '@/components/pages/user/cart/complete/Container.js'

export default {
  components: {
    Container
  },
  mixins: [
    CommonMixin, ViewMixin
  ]
}
</script>

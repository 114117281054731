<template>
  <v-card
    :class="['outset', 'welfare-card', 'mx-15', 'px-15', 'py-15']"
  >
    <v-card-title>
      <v-icon>
        mdi-hand-heart
      </v-icon>
      ポイント交換が完了しました。
    </v-card-title>

    <v-card-text>
      確認メールを送信していますのでご確認ください。
    </v-card-text>
    <v-card-text>
      注文番号：<a href="/exchange/history">{{ this.$route.params.orderNumber }}</a>
    </v-card-text>
    <v-card-actions>
      <v-btn
        rounded
        outlined
        color="primary"
        :class="['small-outset', 'mr-5', 'mb-5', 'px-5']"
        @click="$router.push({ name: 'ItemList' }, () => {})"
      >
        アイテム一覧へ
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
}
</script>
